@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html {
    font-family: Proxima Nova, system-ui, sans-serif;
  }

  a {
    @apply block text-xl lg:inline-block lg:mt-0 text-slate-700 mr-8 relative after:bg-slate-700
    after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300;
  }
  
  .button {
    @apply bg-sky-500 text-white hover:bg-sky-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: 27px;
  }

  select:not([size]) {
    @apply pr-7 mr-0;
  }

}